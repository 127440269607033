<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">给药记录</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">给药记录</h4>
        <el-button size="small" plain class="return" @click="$emit('changePage', 1,null,1)">返回列表页</el-button>
      </div>
      <el-form ref="addForm" size="small" :model="drugForm" class="addForm" label-suffix=":"
               label-width="90px">
        <div class="flex">
          <el-form-item label="给药日期">
            <el-date-picker class="smallWidth"
                            v-model="drugForm.handleTime"
                            type="date"
                            placeholder="请输入给药日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="给药方式">
            <el-input v-model="drugForm.way" placeholder="请输入给药方式" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="给药体积">
            <el-input v-model="drugForm.volume" placeholder="请输入给药体积" class="smallWidth">
              <span slot="suffix">μL</span>
            </el-input>
          </el-form-item>
        </div>
        <!--        新增-->
        <template v-if="state==null">
          <el-tabs v-model="tabsValue" class="tabsCage">
            <el-tab-pane
                :key="index"
                v-for="(item, index) in tabs"
                :name="item.name"
            >
              <span slot="label">{{ item.name }}</span>
              <el-table
                  :data="item.tablelList" border
                  class="tableMember"
                  :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                  style="width: calc(100% - 12px);font-size:14px;color:#333">
                <el-table-column label="笼号" prop="cageNo" show-overflow-tooltip></el-table-column>
                <el-table-column label="动物编号" prop="aniNo" show-overflow-tooltip></el-table-column>
                <el-table-column label="耳号" prop="overbit" show-overflow-tooltip></el-table-column>
                <el-table-column label="性别">
                  <template slot-scope="scope">{{ scope.row.gender == 1 ? '♂' : '♀' }}</template>
                </el-table-column>
                <el-table-column label="是否给药完成" width="150" class-name="isClass">
                  <template slot-scope="scope">
                    <!--              0:未给药  1：给药-->
                    <el-radio-group v-model="scope.row.isDose" class="radioGroup">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                  </template>
                </el-table-column>
                <el-table-column label="备注" min-width="200" class-name="isClass">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.remark" placeholder="请输入备注" class="smallWidth"></el-input>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </template>
        <!--编辑-->
        <template v-else>
          <el-tabs v-model="nowNumber" class="tabsCage">
            <el-tab-pane
                :key="key"
                v-for="(item,key) in tabsSave"
                :name="key"
            >
              <span slot="label">{{ key }}</span>
              <el-table
                  :data="item" border
                  class="tableMember"
                  :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                  style="width: calc(100% - 12px);font-size:14px;color:#333">
                <el-table-column label="笼号" prop="cageNo" show-overflow-tooltip></el-table-column>
                <el-table-column label="动物编号" prop="aniNo" show-overflow-tooltip></el-table-column>
                <el-table-column label="耳号" prop="overbit" show-overflow-tooltip></el-table-column>
                <el-table-column label="性别">
                  <template slot-scope="scope">{{ scope.row.gender == 1 ? '♂' : '♀' }}</template>
                </el-table-column>
                <el-table-column label="是否给药完成" width="150">
                  <template slot-scope="scope">
                    <!--              0:未给药  1：给药-->
                    <template v-if="scope.row.show">
                      <el-radio-group v-model="scope.row.isDose" class="radioGroup">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </template>
                    <span v-else>{{ scope.row.isDose == 1 ? '是' : '否' }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="备注" min-width="200">
                  <template slot-scope="scope">
                    <template v-if="scope.row.show">
                      <el-input v-model="scope.row.remark" placeholder="请输入备注" class="smallWidth"></el-input>
                    </template>
                    <span v-else>{{ scope.row.remark }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" v-if="state==0||state==3">
                  <template slot-scope="scope">
                    <div class="editColor">
                      <el-button type="text" v-if="!scope.row.show" @click="edit(scope.row,scope.$index,item)">编辑
                      </el-button>
                      <el-button type="text" v-else @click="save(scope.row,scope.$index,item)">保存</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </template>

        <h3 class="tit">指定复核人</h3>
        <el-form-item label="复核人员">
          <el-radio-group v-model="drugForm.reviewName" class="radioGroup">
            <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                      @click.native="getAppId(item.userId,item.name)"
            > {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="fromSave">
          <el-button type="primary" size="small" @click="submitForm(1)">记录完成</el-button>
          <el-button type="primary" plain size="small" @click="submitForm(0)">保存</el-button>
          <template v-if="drugId">
            <el-button type="primary" plain size="small" @click="$emit('changePage', 3,drugId,1)">导出预览</el-button>
          </template>
        </div>
      </el-form>
    </div>
  </div>

</template>

<script>
export default {
  name: "drugDel",
  props: ['id', 'drugId'],
  data() {
    return {
      drugForm: {
        handleTime: "",
        way: "",
        volume: "",
        reviewer: null,
        reviewName: "",
        doseDetailList: []
      },
      userList: [],
      state: null,
      // 新增
      tabsValue: '',
      tabs: [],
      // 保存或者提交
      tabsSave: {},
      nowNumber: 0
    }
  },
  mounted() {
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
// 获取详情
    if (this.drugId) {
      this.getDoseDetail()
    } else {
      this.getGroup()
    }

  },
  methods: {
    // 指定复核人员
    getAppId(id, name) {
      this.drugForm.reviewer = id
      this.drugForm.reviewName = name
    },
    // 获取实验组信息
    getGroup() {
      this.$get('/group/' + this.id).then(res => {
        if (res.status == 200) {
          res.data.forEach(item => {
            item['tablelList'] = []
            item.groupCageList.forEach(obj => {
              item['tablelList'].push({
                aniNo: obj.aniNo,
                cageNo: obj.cageNo,
                gender: obj.gender,
                groupAniId: obj.groupAniId,
                groupId: obj.groupId,
                groupName: item.name,
                isDose: 1,
                overbit: obj.overbit,
                remark: ""
              })
            })
          })
          this.tabs = res.data
          this.tabsValue = res.data[0].name
        }
      })
    },
    // 获取给药详情
    getDoseDetail() {
      this.$get('/subject/dose/' + this.drugId).then(res => {
        if (res.status == 200) {
          this.state = res.data.dose.state
          this.drugForm = {
            handleTime: res.data.dose.handleTime,
            way: res.data.dose.way,
            volume: res.data.dose.volume,
            reviewer: res.data.dose.reviewer,
            reviewName: res.data.dose.reviewName,
          }
          this.tabsSave = res.data.groupMap
          let arr = []
          if (this.nowNumber == 0) {
            for (const key in res.data.groupMap) {
              arr.push(key)
              res.data.groupMap[key].map(i => {
                i.show = false
                return i
              })
            }
            this.nowNumber = arr[0]
          }
        }
      })
    },
    // 编辑
    edit(row, index, table) {
      row.show = !row.show
      this.$set(table, index, row)
    },
    // 保存
    save(row, index, table) {
      this.$put('/subject/dose/detail', row).then(res => {
        if (res.status == 200) {
          this.$message.success("修改成功")
          row.show = false
          this.$set(table, index, row)
         /* this.$nextTick(()=>{
            this.getUpdate(row.doseId, row.groupId,item)
          })*/
        }
      })
    },
    // 修改后获取
    getUpdate(doseId, groupId,table) {
      // doseId（给药id） 和 groupId（组id)
      this.$get('/subject/dose/detail/' + doseId, {groupId: groupId}).then(res => {
        if (res.status == 200) {
          table = res.data
        }
      })
    },
    // 提交信息
    submitForm(state) {
      this.drugForm.state = state
      this.drugForm.subId = Number(this.id)
      let url;
      if ($.trim(this.drugForm.reviewer).length === 0) {
        this.$message.warning("请选择复核人")
        return false;
      }
      if (this.state == null) {
        let lists = [];
        this.tabs.forEach(item => {
          item.tablelList.forEach(obj => {
            let newObj = {
              aniNo: obj.aniNo,
              cageNo: obj.cageNo,
              gender: obj.gender,
              groupAniId: obj.groupAniId,
              groupId: obj.groupId,
              groupName: obj.groupName,
              isDose: obj.isDose,
              overbit: obj.overbit,
              remark: obj.remark
            }
            lists.push(newObj)
          })
        })
        this.drugForm.doseDetailList = lists
        // 新增
        url = this.$postJson('/subject/dose', this.drugForm)
      } else {
        this.$delete(this.drugForm, 'doseDetailList')
        this.drugForm.id = this.drugId
        // 修改
        url = this.$putJson('/subject/dose', this.drugForm)
      }
      url.then(res => {
        if (res.status == 201 || res.status == 200) {
          this.$message.success('提交成功')
          this.$emit('changePage', 1, null, 1)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
